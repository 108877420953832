<template>
  <div class="collection-container">
    <div style="padding: 0px 100px;box-sizing: border-box">
      <div class="flex-row" style="margin-top: 30px;align-items: center">
        <img src="../../assets/image/arrow_back.png" style="width: 44px;height: 44px;cursor: pointer" @click="goHome">
        <p class="collection-list" style="flex: 1">Collection List</p>
      </div>
      <div class="search-container">
        <img src="../../assets/image/search.png" style="width: 24px;height: auto">
        <input class="custom-input" v-model="search_word" placeholder="Please input the key words to search"
               @input="handleInputChanged">
      </div>
      <div style="margin-top: 20px;width: 100%;padding-left:84px;box-sizing: border-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="(quiz_info,index) in showDataList"
                            :name="''+(index+1)" :key="index" style="margin-bottom: 20px">
            <template slot="title">
              <div class="flex-row" style="width: 100%;align-items: center">
                <p style="flex: 1">{{ (index + 1) + '.' + quiz_info.question_title }}</p>
                <img src="../../assets/image/icon_collect.png"
                     style="width: 28px;height: auto;cursor: pointer;padding-right: 20px;"
                     @click.stop="removeCollection(quiz_info.collection_id)">
              </div>

            </template>
            <div style="margin-top: 10px;width: 100%;padding-left: 40px;box-sizing: border-box">
              <CollectionOptionView :quiz-info="quiz_info" :note="quiz_info.note"/>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserId} from "../../utils/store"
import {getCollectionList, removeCollectionQuiz, searchCollection} from "../../api/eae";
import CollectionOptionView from "./components/CollectionOptionView.vue"
import {Loading} from 'element-ui';

export default {
  name: "CollectionList",
  components: {CollectionOptionView},
  data() {
    return {
      search_word: '',
      activeName: '1',
      user_id: getUserId(),
      class_id: this.$route.query.class_id,
      original_dataList: [],
      showDataList: [],
      loadingInstance: undefined
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      this.dataList = []
      this.original_dataList = []
      getCollectionList(this.user_id, this.class_id).then((res) => {
        this.original_dataList = res.data.data
        this.showDataList = res.data.data
      })
    },
    removeCollection(collection_id) {
      //remove collection
      this.$alert('Are you sure remove this collection？', 'Tips', {
        confirmButtonText: 'Confirm',
        callback: action => {
          if (action == 'confirm') {
            removeCollectionQuiz(collection_id).then((res) => {
              this.fetchData()
              this.$message.success('Remove Collection Success')
            })
          }
        }
      })
    },
    handleInputChanged(e) {
      this.searchCollection(this.search_word)
    },
    async searchCollection(inputVal) {
      if (inputVal == '') {
        this.showDataList = this.original_dataList
        return
      }
      this.showLoadingView()
      try {
        const response = await searchCollection(this.user_id, this.class_id, inputVal)
        if (this.search_word == inputVal) {
          this.showDataList = response.data.data
        }
      } finally {
        this.closeLoading()
      }
    },
    goHome() {
      this.$router.go(-1)
    },
    showLoadingView() {
      this.closeLoading()
      this.loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading',
        text: 'Loading'
      });
    },
    closeLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
  }
}
</script>

<style scoped lang="less">

::-webkit-scrollbar {
  display: none;
}

.search-container {
  margin-top: 20px;
  margin-left: 84px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 30px;
  box-sizing: border-box;
  width: 500px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #CCCCCC;
}

.custom-input {
  padding-left: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
}

.collection-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.collection-list {
  margin-left: 40px;
  font-size: 28px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FF6450
}


/deep/ .el-collapse {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

/deep/ .el-collapse.is-active {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

/deep/ .el-collapse-item__header {
  padding: 20px 20px;
  box-sizing: border-box;
  height: unset;
  min-height: 60px;
  box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #F2F6FF;
  color: #FF6450;
  cursor: pointer;
  border-bottom-width: 0px;
  border-bottom-color: transparent;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  transition: border-bottom-color .3s;
  outline: 0;
}

/deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}
</style>
