import { render, staticRenderFns } from "./CollectionOptionView.vue?vue&type=template&id=1ad0b360&scoped=true"
import script from "./CollectionOptionView.vue?vue&type=script&lang=js"
export * from "./CollectionOptionView.vue?vue&type=script&lang=js"
import style0 from "./CollectionOptionView.vue?vue&type=style&index=0&id=1ad0b360&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad0b360",
  null
  
)

export default component.exports